body {
  background: linear-gradient(#5173c3 0%, #034b5200 100%) no-repeat fixed;
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
}

div#root {
  max-width: 600px;
  margin: 0 auto;
}
/*# sourceMappingURL=index.b2a06243.css.map */

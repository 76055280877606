body {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(to bottom, rgb(81, 115, 195) 0%, rgba(3,75, 82, 0) 100%);
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

div#root {
    margin: 0 auto;
    max-width: 600px;
}